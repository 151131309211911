/*　【リリース時の手順】
 * 1. 新しいバージョンのインストーラ名、URLの定数定義を追加する
 * 2. [ソフト名] latestの定義箇所に、ダウンロード画面に表示するバージョンの定数定義を入れる
 * 3. マニュアルの更新があれば、ファイル名等を修正する
 * 4. 過去のリリース情報ページの更新を行う
 */

/************************************/
/* DF Scanner Pro v1.3.2 */
export const DF_SCANNER_PRO_FILENAME_V1_3_2 = "Setup DF Scanner Pro 1.3.2.exe";
export const DF_SCANNER_PRO_URL_V1_3_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Pro%201.3.2.exe";

/* DF Scanner Lite v1.3.2 */
export const DF_SCANNER_LITE_FILENAME_V1_3_2 = "Setup DF Scanner Lite 1.3.2.exe";
export const DF_SCANNER_LITE_URL_V1_3_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Lite%201.3.2.exe";

/* DF Scanner v1.8.2 */
export const DF_SCANNER_FILENAME_V1_8_2 = "Setup DF Scanner 1.8.2.exe";
export const DF_SCANNER_URL_V1_8_2 = "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%201.8.2.exe";

/* DF Scanner Pro v1.3.1 */
export const DF_SCANNER_PRO_FILENAME_V1_3_1 = "Setup DF Scanner Pro 1.3.1.exe";
export const DF_SCANNER_PRO_URL_V1_3_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Pro%201.3.1.exe";

/* DF Scanner Lite v1.3.1 */
export const DF_SCANNER_LITE_FILENAME_V1_3_1 = "Setup DF Scanner Lite 1.3.1.exe";
export const DF_SCANNER_LITE_URL_V1_3_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Lite%201.3.1.exe";

/* DF Scanner v1.8.1 */
export const DF_SCANNER_FILENAME_V1_8_1 = "Setup DF Scanner 1.8.1.exe";
export const DF_SCANNER_URL_V1_8_1 = "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%201.8.1.exe";

/* DF Scanner Pro v1.3.0 */
export const DF_SCANNER_PRO_FILENAME_V1_3_0 = "Setup DF Scanner Pro 1.3.0.exe";
export const DF_SCANNER_PRO_URL_V1_3_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Pro%201.3.0.exe";

/* DF Scanner Lite v1.3.0 */
export const DF_SCANNER_LITE_FILENAME_V1_3_0 = "Setup DF Scanner Lite 1.3.0.exe";
export const DF_SCANNER_LITE_URL_V1_3_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Lite%201.3.0.exe";

/* DF Scanner v1.8.0 */
export const DF_SCANNER_FILENAME_V1_8_0 = "Setup DF Scanner 1.8.0.exe";
export const DF_SCANNER_URL_V1_8_0 = "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%201.8.0.exe";

/* DF Scanner Pro v1.2.1 */
export const DF_SCANNER_PRO_FILENAME_V1_2_1 = "Setup DF Scanner Pro 1.2.1.exe";
export const DF_SCANNER_PRO_URL_V1_2_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Pro%201.2.1.exe";

/* DF Scanner Lite v1.2.1 */
export const DF_SCANNER_LITE_FILENAME_V1_2_1 = "Setup DF Scanner Lite 1.2.1.exe";
export const DF_SCANNER_LITE_URL_V1_2_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Lite%201.2.1.exe";

/* DF Scanner v1.7.1 */
export const DF_SCANNER_FILENAME_V1_7_1 = "Setup DF Scanner 1.7.1.exe";
export const DF_SCANNER_URL_V1_7_1 = "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%201.7.1.exe";

/* DF Scanner Pro v1.2.0 */
export const DF_SCANNER_PRO_FILENAME_V1_2_0 = "Setup DF Scanner Pro 1.2.0.exe";
export const DF_SCANNER_PRO_URL_V1_2_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Pro%201.2.0.exe";

/* DF Scanner Lite v1.2.0 */
export const DF_SCANNER_LITE_FILENAME_V1_2_0 = "Setup DF Scanner Lite 1.2.0.exe";
export const DF_SCANNER_LITE_URL_V1_2_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%20Lite%201.2.0.exe";

/* DF Scanner v1.7.0 */
export const DF_SCANNER_FILENAME_V1_7_0 = "Setup DF Scanner 1.7.0.exe";
export const DF_SCANNER_URL_V1_7_0 = "https://deepforestlicense.com/DFScanner/Setup%20DF%20Scanner%201.7.0.exe";

/* DF Scanner Pro v1.1.1 */
export const DF_SCANNER_PRO_GPU_FILENAME_V1_1_1 = "Setup cuda11 DF Scanner Pro 1.1.1.exe";
export const DF_SCANNER_PRO_GPU_URL_V1_1_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Pro%201.1.1.exe";
export const DF_SCANNER_PRO_CPU_FILENAME_V1_1_1 = "Setup cpu DF Scanner Pro 1.1.1.exe";
export const DF_SCANNER_PRO_CPU_URL_V1_1_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Pro%201.1.1.exe";

/* DF Scanner Lite v1.1.1 */
export const DF_SCANNER_LITE_GPU_FILENAME_V1_1_1 = "Setup cuda11 DF Scanner Lite 1.1.1.exe";
export const DF_SCANNER_LITE_GPU_URL_V1_1_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Lite%201.1.1.exe";
export const DF_SCANNER_LITE_CPU_FILENAME_V1_1_1 = "Setup cpu DF Scanner Lite 1.1.1.exe";
export const DF_SCANNER_LITE_CPU_URL_V1_1_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Lite%201.1.1.exe";

/* DF Scanner v1.6.1 */
export const DF_SCANNER_GPU_FILENAME_V1_6_1 = "Setup cuda11 DF Scanner 1.6.1.exe";
export const DF_SCANNER_GPU_URL_V1_6_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.6.1.exe";
export const DF_SCANNER_CPU_FILENAME_V1_6_1 = "Setup cpu DF Scanner 1.6.1.exe";
export const DF_SCANNER_CPU_URL_V1_6_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.6.1.exe";

/* DF Scanner Pro v1.1.0 */
export const DF_SCANNER_PRO_GPU_FILENAME_V1_1_0 = "Setup cuda11 DF Scanner Pro 1.1.0.exe";
export const DF_SCANNER_PRO_GPU_URL_V1_1_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Pro%201.1.0.exe";
export const DF_SCANNER_PRO_CPU_FILENAME_V1_1_0 = "Setup cpu DF Scanner Pro 1.1.0.exe";
export const DF_SCANNER_PRO_CPU_URL_V1_1_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Pro%201.1.0.exe";

/* DF Scanner Lite v1.1.0 */
export const DF_SCANNER_LITE_GPU_FILENAME_V1_1_0 = "Setup cuda11 DF Scanner Lite 1.1.0.exe";
export const DF_SCANNER_LITE_GPU_URL_V1_1_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Lite%201.1.0.exe";
export const DF_SCANNER_LITE_CPU_FILENAME_V1_1_0 = "Setup cpu DF Scanner Lite 1.1.0.exe";
export const DF_SCANNER_LITE_CPU_URL_V1_1_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Lite%201.1.0.exe";

/* DF Scanner v1.6.0 */
export const DF_SCANNER_GPU_FILENAME_V1_6_0 = "Setup cuda11 DF Scanner 1.6.0.exe";
export const DF_SCANNER_GPU_URL_V1_6_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.6.0.exe";
export const DF_SCANNER_CPU_FILENAME_V1_6_0 = "Setup cpu DF Scanner 1.6.0.exe";
export const DF_SCANNER_CPU_URL_V1_6_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.6.0.exe";

/* DF Scanner Pro v1.0.2 */
export const DF_SCANNER_PRO_GPU_FILENAME_V1_0_2 = "Setup cuda11 DF Scanner Pro 1.0.2.exe";
export const DF_SCANNER_PRO_GPU_URL_V1_0_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Pro%201.0.2.exe";
export const DF_SCANNER_PRO_CPU_FILENAME_V1_0_2 = "Setup cpu DF Scanner Pro 1.0.2.exe";
export const DF_SCANNER_PRO_CPU_URL_V1_0_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Pro%201.0.2.exe";

/* DF Scanner Lite v1.0.2 */
export const DF_SCANNER_LITE_GPU_FILENAME_V1_0_2 = "Setup cuda11 DF Scanner Lite 1.0.2.exe";
export const DF_SCANNER_LITE_GPU_URL_V1_0_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Lite%201.0.2.exe";
export const DF_SCANNER_LITE_CPU_FILENAME_V1_0_2 = "Setup cpu DF Scanner Lite 1.0.2.exe";
export const DF_SCANNER_LITE_CPU_URL_V1_0_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Lite%201.0.2.exe";

/* DF Scanner v1.5.2 */
export const DF_SCANNER_GPU_FILENAME_V1_5_2 = "Setup cuda11 DF Scanner 1.5.2.exe";
export const DF_SCANNER_GPU_URL_V1_5_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.5.2.exe";
export const DF_SCANNER_CPU_FILENAME_V1_5_2 = "Setup cpu DF Scanner 1.5.2.exe";
export const DF_SCANNER_CPU_URL_V1_5_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.5.2.exe";

/* DF Scanner Pro v1.0.1 */
export const DF_SCANNER_PRO_GPU_FILENAME_V1_0_1 = "Setup cuda11 DF Scanner Pro 1.0.1.exe";
export const DF_SCANNER_PRO_GPU_URL_V1_0_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Pro%201.0.1.exe";
export const DF_SCANNER_PRO_CPU_FILENAME_V1_0_1 = "Setup cpu DF Scanner Pro 1.0.1.exe";
export const DF_SCANNER_PRO_CPU_URL_V1_0_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Pro%201.0.1.exe";

/* DF Scanner Lite v1.0.1 */
export const DF_SCANNER_LITE_GPU_FILENAME_V1_0_1 = "Setup cuda11 DF Scanner Lite 1.0.1.exe";
export const DF_SCANNER_LITE_GPU_URL_V1_0_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Lite%201.0.1.exe";
export const DF_SCANNER_LITE_CPU_FILENAME_V1_0_1 = "Setup cpu DF Scanner Lite 1.0.1.exe";
export const DF_SCANNER_LITE_CPU_URL_V1_0_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Lite%201.0.1.exe";

/* DF Scanner v1.5.1 */
export const DF_SCANNER_GPU_FILENAME_V1_5_1 = "Setup cuda11 DF Scanner 1.5.1.exe";
export const DF_SCANNER_GPU_URL_V1_5_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.5.1.exe";
export const DF_SCANNER_CPU_FILENAME_V1_5_1 = "Setup cpu DF Scanner 1.5.1.exe";
export const DF_SCANNER_CPU_URL_V1_5_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.5.1.exe";

/* DF Scanner Pro v1.0.0 */
export const DF_SCANNER_PRO_GPU_FILENAME_V1_0_0 = "Setup cuda11 DF Scanner Pro 1.0.0.exe";
export const DF_SCANNER_PRO_GPU_URL_V1_0_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Pro%201.0.0.exe";
export const DF_SCANNER_PRO_CPU_FILENAME_V1_0_0 = "Setup cpu DF Scanner Pro 1.0.0.exe";
export const DF_SCANNER_PRO_CPU_URL_V1_0_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Pro%201.0.0.exe";

/* DF Scanner Lite v1.0.0 */
export const DF_SCANNER_LITE_GPU_FILENAME_V1_0_0 = "Setup cuda11 DF Scanner Lite 1.0.0.exe";
export const DF_SCANNER_LITE_GPU_URL_V1_0_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%20Lite%201.0.0.exe";
export const DF_SCANNER_LITE_CPU_FILENAME_V1_0_0 = "Setup cpu DF Scanner Lite 1.0.0.exe";
export const DF_SCANNER_LITE_CPU_URL_V1_0_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%20Lite%201.0.0.exe";

/* DF Scanner v1.5.0 */
export const DF_SCANNER_GPU_FILENAME_V1_5_0 = "Setup cuda11 DF Scanner 1.5.0.exe";
export const DF_SCANNER_GPU_URL_V1_5_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.5.0.exe";
export const DF_SCANNER_CPU_FILENAME_V1_5_0 = "Setup cpu DF Scanner 1.5.0.exe";
export const DF_SCANNER_CPU_URL_V1_5_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.5.0.exe";

/* DF Scanner v1.4.1 */
export const DF_SCANNER_GPU_FILENAME_V1_4_1 = "Setup cuda11 DF Scanner 1.4.1.exe";
export const DF_SCANNER_GPU_URL_V1_4_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.4.1.exe";
export const DF_SCANNER_CPU_FILENAME_V1_4_1 = "Setup cpu DF Scanner 1.4.1.exe";
export const DF_SCANNER_CPU_URL_V1_4_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.4.1.exe";

/* DF Scanner v1.4.0 */
export const DF_SCANNER_GPU_FILENAME_V1_4_0 = "Setup cuda11 DF Scanner 1.4.0.exe";
export const DF_SCANNER_GPU_URL_V1_4_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.4.0.exe";
export const DF_SCANNER_CPU_FILENAME_V1_4_0 = "Setup cpu DF Scanner 1.4.0.exe";
export const DF_SCANNER_CPU_URL_V1_4_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.4.0.exe";

/* DF Scanner v1.3.3 */
export const DF_SCANNER_GPU_FILENAME_V1_3_3 = "Setup cuda11 DF Scanner 1.3.3.exe";
export const DF_SCANNER_GPU_URL_V1_3_3 =
    "https://deepfor/home/chino/workspace/dfgeoserver-app/src/jsestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.3.3.exe";
export const DF_SCANNER_CPU_FILENAME_V1_3_3 = "Setup cpu DF Scanner 1.3.3.exe";
export const DF_SCANNER_CPU_URL_V1_3_3 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.3.3.exe";

/* DF Scanner v1.3.2 */
export const DF_SCANNER_GPU_FILENAME_V1_3_2 = "Setup cuda11 DF Scanner 1.3.2.exe";
export const DF_SCANNER_GPU_URL_V1_3_2 =
    "https://deepfor/home/chino/workspace/dfgeoserver-app/src/jsestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.3.2.exe";
export const DF_SCANNER_CPU_FILENAME_V1_3_2 = "Setup cpu DF Scanner 1.3.2.exe";
export const DF_SCANNER_CPU_URL_V1_3_2 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.3.2.exe";

/* DF Scanner v1.3.1 */
export const DF_SCANNER_GPU_FILENAME_V1_3_1 = "Setup cuda11 DF Scanner 1.3.1.exe";
export const DF_SCANNER_GPU_URL_V1_3_1 =
    "https://deepfor/home/chino/workspace/dfgeoserver-app/src/jsestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.3.1.exe";
export const DF_SCANNER_CPU_FILENAME_V1_3_1 = "Setup cpu DF Scanner 1.3.1.exe";
export const DF_SCANNER_CPU_URL_V1_3_1 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.3.1.exe";

/* DF Scanner v1.2.4 */
export const DF_SCANNER_GPU_FILENAME_V1_2_4 = "Setup cuda11 DF Scanner 1.2.4.exe";
export const DF_SCANNER_GPU_URL_V1_2_4 =
    "https://deepfor/home/chino/workspace/dfgeoserver-app/src/jsestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.2.4.exe";
export const DF_SCANNER_CPU_FILENAME_V1_2_4 = "Setup cpu DF Scanner 1.2.4.exe";
export const DF_SCANNER_CPU_URL_V1_2_4 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.2.4.exe";

/* DF Scanner v1.2.0 */
export const DF_SCANNER_GPU_FILENAME_V1_2_0 = "Setup cuda11 DF Scanner 1.2.0.exe";
export const DF_SCANNER_GPU_URL_V1_2_0 =
    "https://deepfor/home/chino/workspace/dfgeoserver-app/src/jsestlicense.com/DFScanner/Setup%20cuda11%20DF%20Scanner%201.2.0.exe";
export const DF_SCANNER_CPU_FILENAME_V1_2_0 = "Setup cpu DF Scanner 1.2.0.exe";
export const DF_SCANNER_CPU_URL_V1_2_0 =
    "https://deepforestlicense.com/DFScanner/Setup%20cpu%20DF%20Scanner%201.2.0.exe";

/************************************/
/* DF Viewer v1.0.0 */
export const DF_VIEWER_FILENAME_V1_0_0 = "Setup DF Viewer 1.0.0.exe";
export const DF_VIEWER_URL_V1_0_0 = "https://deepforestlicense.com/DFScanner/Setup%20DF%20Viewer%201.0.0.exe";

/* DF Viewer v1.1.0 */
export const DF_VIEWER_FILENAME_V1_1_0 = "Setup DF Viewer 1.1.0.exe";
export const DF_VIEWER_URL_V1_1_0 = "https://deepforestlicense.com/DFScanner/Setup%20DF%20Viewer%201.1.0.exe";

/* DF Viewer v1.2.0 */
export const DF_VIEWER_FILENAME_V1_2_0 = "Setup DF Viewer 1.2.0.exe";
export const DF_VIEWER_URL_V1_2_0 = "https://deepforestlicense.com/DFViewer/Setup%20DF%20Viewer%201.2.0.exe";

/************************************/
/* DF LAT Pro v1.1.0 */
export const DF_LAT_PRO_FILENAME_V1_1_0 = "Setup DF LAT Pro 1.1.0.exe";
export const DF_LAT_PRO_V1_1_0 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%20Pro%201.1.0.exe";

/* DF LAT Lite v1.1.0 */
export const DF_LAT_LITE_FILENAME_V1_1_0 = "Setup DF LAT Lite 1.1.0.exe";
export const DF_LAT_LITE_V1_1_0 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%20Lite%201.1.0.exe";

/* DF LAT Pro v1.0.1 */
export const DF_LAT_PRO_FILENAME_V1_0_1 = "Setup DF LAT Pro 1.0.1.exe";
export const DF_LAT_PRO_V1_0_1 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%20Pro%201.0.1.exe";

/* DF LAT Lite v1.0.1 */
export const DF_LAT_LITE_FILENAME_V1_0_1 = "Setup DF LAT Lite 1.0.1.exe";
export const DF_LAT_LITE_V1_0_1 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%20Lite%201.0.1.exe";

/* DF LAT Pro v1.0.0 */
export const DF_LAT_PRO_FILENAME_V1_0_0 = "Setup DF LAT Pro 1.0.0.exe";
export const DF_LAT_PRO_V1_0_0 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%20Pro%201.0.0.exe";

/* DF LAT Lite v1.0.0 */
export const DF_LAT_LITE_FILENAME_V1_0_0 = "Setup DF LAT Lite 1.0.0.exe";
export const DF_LAT_LITE_V1_0_0 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%20Lite%201.0.0.exe";

/* DF LAT v1.0.4 */
export const DF_LAT_FILENAME_V1_0_4 = "Setup DF LAT 1.0.4.exe";
export const DF_LAT_V1_0_4 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%201.0.4.exe";

/* DF LAT v1.0.3 */
export const DF_LAT_FILENAME_V1_0_3 = "Setup DF LAT 1.0.3.exe";
export const DF_LAT_V1_0_3 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%201.0.3.exe";

/* DF LAT v1.0.2 */
export const DF_LAT_FILENAME_V1_0_2 = "Setup DF LAT 1.0.2.exe";
export const DF_LAT_V1_0_2 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%201.0.2.exe";

/* DF LAT v1.0.0 */
export const DF_LAT_FILENAME_V1_0_0 = "Setup DF LAT 1.0.0.exe";
export const DF_LAT_V1_0_0 = "https://deepforestlicense.com/LAT/Setup%20DF%20LAT%201.0.0.exe";

/************************************/
/* DF BIRD v1.1.0 */
export const DF_BIRD_FILENAME_V1_1_0 = "Setup DF BIRD 1.1.0.exe";
export const DF_BIRD_URL_V1_1_0 = "https://deepforestlicense.com/DFBIRD/Setup%20DF%20BIRD%201.1.0.exe";

/* DF BIRD v1.0.3 */
export const DF_BIRD_FILENAME_V1_0_3 = "Setup DF BIRD 1.0.3.exe";
export const DF_BIRD_URL_V1_0_3 = "https://deepforestlicense.com/DFBIRD/Setup%20DF%20BIRD%201.0.3.exe";

/* DF BIRD v1.0.2 */
export const DF_BIRD_FILENAME_V1_0_2 = "Setup DF BIRD 1.0.2.exe";
export const DF_BIRD_URL_V1_0_2 = "https://deepforestlicense.com/DFBIRD/Setup%20DF%20BIRD%201.0.2.exe";

/* DF BIRD v1.0.1 */
export const DF_BIRD_FILENAME_V1_0_1 = "Setup DF BIRD 1.0.1.exe";
export const DF_BIRD_URL_V1_0_1 = "https://deepforestlicense.com/DFBIRD/Setup%20DF%20BIRD%201.0.1.exe";

/* DF BIRD v1.0.0 */
export const DF_BIRD_FILENAME_V1_0_0 = "Setup DF BIRD 1.0.0.exe";
export const DF_BIRD_URL_V1_0_0 = "https://deepforestlicense.com/DFBIRD/Setup%20DF%20BIRD%201.0.0.exe";

/************************************/
/* DF Scanner Pro latest */
// DF Scanner Pro version ファイル名
export const DF_SCANNER_PRO_FILENAME = DF_SCANNER_PRO_FILENAME_V1_3_2;
// DF Scanner Pro version ダウンロードURL
export const DF_SCANNER_PRO_URL = DF_SCANNER_PRO_URL_V1_3_2;

/************************************/
/* DF Scanner Lite latest */
// DF Scanner Lite version ファイル名
export const DF_SCANNER_LITE_FILENAME = DF_SCANNER_LITE_FILENAME_V1_3_2;
// DF Scanner Lite version ダウンロードURL
export const DF_SCANNER_LITE_URL = DF_SCANNER_LITE_URL_V1_3_2;

/************************************/
/* DF Scanner latest */
// DF Scanner version ファイル名
export const DF_SCANNER_FILENAME = DF_SCANNER_FILENAME_V1_8_2;
// DF Scanner version ダウンロードURL
export const DF_SCANNER_URL = DF_SCANNER_URL_V1_8_2;

/************************************/
/* DF LAT Pro latest */
// DF LAT Pro ファイル名
export const DF_LAT_PRO_FILENAME = DF_LAT_PRO_FILENAME_V1_1_0;
// DF LAT Pro ダウンロードURL
export const DF_LAT_PRO_URL = DF_LAT_PRO_V1_1_0;

/************************************/
/* DF LAT Lite latest */
// DF LAT Lite ファイル名
export const DF_LAT_LITE_FILENAME = DF_LAT_LITE_FILENAME_V1_1_0;
// DF LAT Lite ダウンロードURL
export const DF_LAT_LITE_URL = DF_LAT_LITE_V1_1_0;

/************************************/
/* DF Viewer latest */
// DF Viewer ファイル名
export const DF_VIEWER_FILENAME = DF_VIEWER_FILENAME_V1_2_0;
// DF Viewer ダウンロードURL
export const DF_VIEWER_URL = DF_VIEWER_URL_V1_2_0;

/************************************/
/* DF BIRD latest */
// DF BIRD ファイル名
export const DF_BIRD_FILENAME = DF_BIRD_FILENAME_V1_1_0;
// DF Viewer ダウンロードURL
export const DF_BIRD_URL = DF_BIRD_URL_V1_1_0;

/************************************/
// [日本語]ドローン計測諸元 ファイル名
export const RECOMMENDED_UAV_FLIGHT_FILENAME_JA = "日本語";
// [日本語]ドローン計測諸元 ダウンロードURL
export const RECOMMENDED_UAV_FLIGHT_URL_JA =
    "https://deepforestlicense.com/DFScanner/docs/弊社製品仕様におけるドローン計測につきまして.pdf";
// [英語]ドローン計測諸元 ファイル名
export const RECOMMENDED_UAV_FLIGHT_FILENAME_EN = "English";
// [英語]ドローン計測諸元 ダウンロードURL
export const RECOMMENDED_UAV_FLIGHT_URL_EN =
    "https://deepforestlicense.com/DFScanner/docs/Recommended%20UAV%20Flight%20and%20Photography%20Method%20for%20Our%20Software.pdf";

/************************************/
// DF Scanner テストデータ ファイル名
export const DF_SCANNER_TESTDATA_FILENAME = "Test_Date.zip";
// DF Scanner テストデータ ダウンロードURL
export const DF_SCANNER_TESTDATA_URL = "https://deepforestlicense.com/DFScanner/Test_Data.zip";

/************************************/
// DF Scanner Pro ユーザーマニュアル
export const DF_SCANNER_PRO_MANUAL_JA_FILENAME = "DF Scanner Pro 使用説明書1.3.pdf";
export const DF_SCANNER_PRO_MANUAL_JA_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/11/35cb7634f4da2e3576ee18be17f76cf1.pdf";
export const DF_SCANNER_PRO_MANUAL_EN_FILENAME = "DF Scanner Pro User's Guide1.3.pdf";
export const DF_SCANNER_PRO_MANUAL_EN_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/11/DF-Scanner-Pro-Users-Guide1.3.pdf";

/************************************/
// DF Scanner Lite ユーザーマニュアル
export const DF_SCANNER_LITE_MANUAL_JA_FILENAME = "DF Scanner Lite 使用説明書1.3.pdf";
export const DF_SCANNER_LITE_MANUAL_JA_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/11/685933821a6c7de43ac6a11277254669.pdf";
export const DF_SCANNER_LITE_MANUAL_EN_FILENAME = "DF Scanner Lite User's Guide1.3.pdf";
export const DF_SCANNER_LITE_MANUAL_EN_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/11/DF-Scanner-Lite-Users-Guide1.3.pdf";

/************************************/
// DF LAT PRO ユーザーマニュアル
export const DF_LAT_PRO_MANUAL_JA_FILENAME = "DF LAT Pro使用説明書1.1.0.pdf";
export const DF_LAT_PRO_MANUAL_JA_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/06/007c4ff349d17c518a53aa7c2d3c7174.pdf";
export const DF_LAT_PRO_MANUAL_EN_FILENAME = "DF LAT Pro User's Guide1.1.0.pdf";
export const DF_LAT_PRO_MANUAL_EN_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/06/DF-LAT-Pro-Users-Guide.pdf";

/************************************/
// DF LAT LITE ユーザーマニュアル
export const DF_LAT_LITE_MANUAL_JA_FILENAME = "DF LAT Lite使用説明書1.1.0.pdf";
export const DF_LAT_LITE_MANUAL_JA_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/06/48842c4fd19d1d062d98b574be5024fb.pdf";
export const DF_LAT_LITE_MANUAL_EN_FILENAME = "DF LAT Lite User's Guide1.1.0.pdf";
export const DF_LAT_LITE_MANUAL_EN_URL =
    "https://deepforestlicense.com/wp-content/uploads/2024/06/DF-LAT-Lite-Users-Guide.pdf";

/************************************/
// DF Viewer ユーザーマニュアル
export const DF_VIEWER_MANUAL_JA_FILENAME = "DF Viewer 簡単操作マニュアル.pdf";
export const DF_VIEWER_MANUAL_JA_URL = "https://deepforestlicense.com/DFViewer/DF-Viewer-Users-Guide.pdf";

/************************************/
// DF BIRD ユーザーマニュアル
export const DF_BIRD_MANUAL_JA_FILENAME = "DF BIRD 使用説明書.pdf";
export const DF_BIRD_MANUAL_JA_URL =
    "https://deepforestlicense.com/wp-content/uploads/2025/02/ac16427401407341de17e9f49c6a29eb.pdf";
export const DF_BIRD_MANUAL_EN_FILENAME = "DF BIRD User's Guide.pdf";
export const DF_BIRD_MANUAL_EN_URL =
    "https://deepforestlicense.com/wp-content/uploads/2025/02/DF-BIRD-Users-Guide1.1.pdf";

/************************************/
// DF Walker Google Playストア リンク
export const DF_WALKER_GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=com.jp.dfwalker&hl=ja";
